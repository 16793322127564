<template>
  <aside>
    <div class="sidebar-widget">
      <h3 class="sidebar-title">{{ serviceSidebar.title }}</h3>
      <ul class="sidebar-list">
        <li v-for="list in serviceSidebar.lists" :key="list.id">
          <router-link :to="list.url">
            <i :class="list.icon"></i>
            {{ list.name }}
          </router-link>
        </li>
      </ul>
    </div>
    <div class="sidebar-widget">
      <h3 class="sidebar-title">相关文件</h3>
      <ul class="sidebar-list">
        <li>
          <a href="#">
            <i class="fa fa-file-pdf-o"></i>
            会员手册.pdf
          </a>
        </li>
        <li>
          <a href="#">
            <i class="fa fa-file-word-o"></i>
            会员手册.pdf
          </a>
        </li>
      </ul>
    </div>
  </aside>
</template>

<script>
export default {
  props: ["serviceSidebar"],
};
</script>